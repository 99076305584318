import { apiurlName, SharedService } from './../../shared/services/shared.service';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { Campaign_List, CampaignSalesOrder_List } from './../../../assets/model/Campaign';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { Prospects, ProspectList } from '../../../assets/model/ProspectRoute';
import { ArchiveParams, Prospect_SalesOrder } from '../../../assets/model/prospect';
import * as internal from 'stream';
import * as moment from 'moment';

@Injectable({ providedIn: 'root' })
export class ProspectRouteService {
  public prospectroutes: ProspectList[];
  private baseAPIurl = '';
  // Default constructor invoked
  constructor(private http: HttpClient, private sharedService: SharedService) {
    this.baseAPIurl = sharedService.splitCurrentURL(apiurlName.dashboardProspectApiEndpoint)
  }


  getProspectRoute(customerEmail: string, advisor: string = null): Observable<any> {
    const param = new HttpParams()
      .set('advisor', advisor == null || advisor == 'All' ? '' : advisor);

    return this.http.get(this.baseAPIurl + `api/dashboard/prospectroutes/${customerEmail}`, { observe: 'response', params: param }).pipe(map(data => {
      return data.body
    }, catchError(this.handleError)
    ));
  }
  GetZiFlowUrldata(SOWNumber: string): Observable<any> {
    const param = new HttpParams()
      .set('SOWNumber', SOWNumber == null ? '' : SOWNumber);

    return this.http.get(this.baseAPIurl + `api/dashboard/Ziflowurldata`, { observe: 'response', params: param }).pipe(map(data => {
      return data.body
    }, catchError(this.handleError)
    ));
  }
  getTimeZone(): Observable<any> {
    return this.http.get(this.baseAPIurl + `api/dashboard/timezone`, { observe: 'response' }).pipe(map(data => {
      return data.body
    }, catchError(this.handleError)
    ));
  }

  getPracticeArea(): Observable<any> {
    return this.http.get(this.baseAPIurl + `api/dashboard/practicearea`, { observe: 'response' }).pipe(map(data => {
      return data.body
    }, catchError(this.handleError)
    ));
  }

  //    getWeek():Observable<any> { 
  //     return this.http.get(this.baseAPIurl+`api/dashboard/week`,{observe: 'response'}).pipe(map(data => {                                    
  //       return data.body
  //     },catchError(this.handleError)               
  //   ));
  //  }

  getLeadsInfoForm(clientId: number, inquiryId: number): Observable<any> {
    const param = new HttpParams()
      .set('clientId', clientId == null ? "" : clientId)
      .set('inquiryId', inquiryId == null ? "" : inquiryId);
    // console.log("param:"+param);
    return this.http.get(this.baseAPIurl + `api/leadsinfo`, { observe: 'response', params: param }).pipe(map(data => {
      return data.body
    }, catchError(this.handleError)
    ));
  }

  //  getProspectLeads(customerId:number,clientId:number,timezone:string, practicearea:string,
  //    timeframe:string, fromdate:string,  todate:string, isBillable:number, isNonBillable:number,invoiceno:string
  //   ):Observable<any> { 
  //   const param = new HttpParams()
  //      .set('customerId', customerId==null?"":sessionStorage.getItem('customerid'))
  //      .set('clientId', clientId==null?"":clientId)
  //      .set('timezone', timezone==null?"":timezone)
  //      .set('practicearea', practicearea==null?"":practicearea)
  //      .set('timeframe', timeframe==null?"":timeframe)
  //      .set('fromdate', fromdate==null?"":fromdate)
  //      .set('todate', todate==null?"":todate)
  //      .set('isBillable', isBillable==null?"":isBillable)
  //      .set('isNonBillable', isNonBillable==null?"":isNonBillable)
  //      .set('invoice', invoiceno==undefined || invoiceno==null || invoiceno==''?null:invoiceno);
  //       return this.http.get(this.baseAPIurl+`api/dashboard/prospectleads`,{observe: 'response',params:param}).pipe(map(data => {                                    
  //        return data.body
  //        },catchError(this.handleError)               
  //      ));
  //   }

  getProspectLeads(customerNumber: string, clientId: number, timezone: string, practicearea: string,
    timeframe: string, fromdate: string, todate: string, isBillable: number, isNonBillable: number, invoiceno: string
  ): Observable<any> {
    const param = new HttpParams()
      //.set('customerId', customerId==null?"":localStorage.getItem('customerid'))
      .set('clientId', clientId == null ? "" : clientId)
      .set('timezone', timezone == null ? "" : timezone)
      .set('practicearea', practicearea == null ? "" : practicearea)
      .set('timeframe', timeframe == null ? "" : timeframe)
      .set('fromdate', fromdate == null ? "" : fromdate)
      .set('todate', todate == null ? "" : todate)
      .set('isBillable', isBillable == null ? "" : isBillable)
      .set('isNonBillable', isNonBillable == null ? "" : isNonBillable)
      .set('invoice', invoiceno == undefined || invoiceno == null || invoiceno == '' ? null : invoiceno);
    return this.http.get(this.baseAPIurl + `api/dashboard/prospectleads/${customerNumber}`, { observe: 'response', params: param }).pipe(map(data => {
      return data.body
    }, catchError(this.handleError)
    ));
  }

  getProspectLeadsExports(customerNumber: string, clientId: number, timezone: string, practicearea: string,
    timeframe: string, fromdate: string, todate: string, isBillable: number, isNonBillable: number, invoiceno: string
  ): Observable<any> {
    const param = new HttpParams()
      //.set('customerId', customerId==null?"":sessionStorage.getItem('customerid'))
      .set('clientId', clientId == null ? "" : clientId)
      .set('timezone', timezone == null ? "" : timezone)
      .set('practicearea', practicearea == null ? "" : practicearea)
      .set('timeframe', timeframe == null ? "" : timeframe)
      .set('fromdate', fromdate == null ? "" : fromdate)
      .set('todate', todate == null ? "" : todate)
      .set('isBillable', isBillable == null ? "" : isBillable)
      .set('isNonBillable', isNonBillable == null ? "" : isNonBillable)
      .set('invoice', invoiceno == undefined || invoiceno == null || invoiceno == '' ? null : invoiceno);
    return this.http.get(this.baseAPIurl + `api/dashboard/prospectleadexports/${customerNumber}`, { responseType: 'blob' as 'json', params: param });
  }

  // Error handling
  handleError(error: { error: { message: string; }; status: any; message: any; }) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    }
    else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }
}

@Injectable({ providedIn: 'root' })
export class Campaign {
}

@Injectable({ providedIn: 'root' })
export class CampaignService {
  private baseAPIurl = '';
  // Default constructor invoked
  constructor(private http: HttpClient, private sharedService: SharedService) {
    this.baseAPIurl = sharedService.splitCurrentURL(apiurlName.campaignApiEndPoint)
  }
  httpOptions = {

    headers: new HttpHeaders({

      'Content-Type': 'application/json'

    }),

  }
  //customerId = { customerId: '172869' }  //Bind Dynamic values for customerid after doing one login 

  // Function call To Get Campaigns
  getCampaigns(customerNumber: string, customerEmail: string): Observable<Campaign_List[]> {
    const param = new HttpParams()
      .set('customerEmail', customerEmail == null ? "" : customerEmail)
    return this.http.get<Campaign_List[]>(this.baseAPIurl + `api/campaign/${customerNumber}`, { observe: 'response', params: param }).pipe(map(data => {
      return data.body
    }, catchError(this.handleError)
    ));
  }

  deleteCampaign(campaignId: number): Observable<any> {

    return this.http.delete<any>(this.baseAPIurl + `api/deleteCampaign/${campaignId}`, { observe: 'response' }).pipe(map(data => {
      return data.body
    }, catchError(this.handleError)
    ));
  }

  // Function call To Create Campaigns
  CreateCampaign(Campaign_List: Campaign): Observable<Campaign_List[]> {
    var body = JSON.stringify(Campaign_List);
    //const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Basic ' + btoa('admin:admin') }) }; 
    return this.http.post<Campaign_List[]>(this.baseAPIurl + 'api/campaign', body, this.httpOptions)
  }

  //Update SalesOrderCampaign
  UpdateSalesOrderCampaign(CampaignSalesOrder_List: Campaign): Observable<CampaignSalesOrder_List[]> {
    var body = JSON.stringify(CampaignSalesOrder_List);
    //const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Basic ' + btoa('admin:admin') }) };    
    return this.http.put<CampaignSalesOrder_List[]>(this.baseAPIurl + 'api/campaign', body, this.httpOptions)
  }
  // Error handling

  handleError(error: { error: { message: string; }; status: any; message: any; }) {
    let errorMessage = 'Unable to connect to server';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    }
    else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }
}

@Injectable({ providedIn: 'root' })
export class ProspectsSalesOrderService {

  public salesOrderDetails: Prospect_SalesOrder[];
  private baseAPIurl = '';
  // Default constructor invoked
  constructor(private http: HttpClient, private sharedService: SharedService) {
    this.baseAPIurl = sharedService.splitCurrentURL(apiurlName.dashboardProspectApiEndpoint)
  }
  customerId = { customerId: sessionStorage.getItem('customerid') }  //Bind Dynamic values for customerid after doing one login  

  // public getAllsalesOrderDetails():Observable<any>{
  //     return this.http.get(this.baseAPIurl + 'api/leadsalesorders/', {observe: 'response',params:this.customerId}).pipe(map(data => {                      
  //      return data.body},catchError(this.handleError)           
  //   )); 
  // }

  // public getAllsalesOrderDetails(customerNumber:string,invoice:string,isOpenSalesOrder:boolean):Observable<any>{

  //   const param = new HttpParams()
  //    .set('invoice', invoice==undefined || invoice==null || invoice==''?null:invoice)
  //    .set('isOpenSalesOrder',isOpenSalesOrder);
  //     return this.http.get(this.baseAPIurl + `api/leadsalesorders/${customerNumber}`, {observe: 'response',params:param}).pipe(map(data => {                      
  //      return data.body},catchError(this.handleError)           
  //   )); 
  // }
  //   public getAllsalesOrderDetails(isOpenSalesOrder:boolean,customerEmail:string,FromDate,ToDate,userviewtype):Observable<any>{
  //     const param = new HttpParams()   
  //     .set('isOpenSalesOrder',isOpenSalesOrder)
  //     .set('customerEmail',customerEmail)
  //     .set('FromDate', FromDate==undefined || FromDate==null || FromDate==''?'':FromDate)
  //     .set('ToDate', ToDate==undefined || ToDate==null || ToDate==''?'':ToDate)
  //     .set('UserViewType',userviewtype)
  //      return this.http.get(this.baseAPIurl + `api/leadsalesorders`, {observe: 'response',params:param}).pipe(map(data => {                      
  //       return data.body},catchError(this.handleError)           
  //    )); 
  //  }
  public getAllsalesOrderDetails(isOpenSalesOrder: boolean, customerEmail: string, FromDate, ToDate, userviewtype, campaignId: number, ProspectLastName: string, ProspectPhone: string, ProspectEmail: string, Advisor: string): Observable<any> {
    const param = new HttpParams()
      .set('isOpenSalesOrder', isOpenSalesOrder)
      .set('customerEmail', customerEmail)
      .set('FromDate', FromDate == undefined || FromDate == null || FromDate == '' ? '' : FromDate)
      .set('ToDate', ToDate == undefined || ToDate == null || ToDate == '' ? '' : ToDate)
      .set('UserViewType', userviewtype)
      .set('campaignId', campaignId)
      .set('ProspectLastName', ProspectLastName == undefined || ProspectLastName == null || ProspectLastName == '' ? '' : ProspectLastName)
      .set('ProspectPhone', ProspectPhone == undefined || ProspectPhone == null || ProspectPhone == '' ? '' : ProspectPhone)
      .set('ProspectEmail', ProspectEmail == undefined || ProspectEmail == null || ProspectEmail == '' ? '' : ProspectEmail)
      .set('Advisor', Advisor == undefined || Advisor == null || Advisor == '' ? '' : Advisor)
    return this.http.get(this.baseAPIurl + `api/leadsalesorders`, { observe: 'response', params: param }).pipe(map(data => { return data.body }, catchError(this.handleError)));
  }

  public getAllsalesOrderDetailsPagination(isOpenSalesOrder: boolean, customerEmail: string, FromDate, ToDate, userviewtype, campaignId: number, ProspectLastName: string, ProspectPhone: string, ProspectEmail: string, Advisor: string, startrownumber: number, endrownumber: number, showArchivedData: boolean = true, noteSearch: string = '', venueSearch: string = ''): Observable<any> {
    if (userviewtype === 'events' && Advisor === 'All') {
      Advisor = '';
    }
    const param = new HttpParams()
      .set('isOpenSalesOrder', isOpenSalesOrder)
      .set('customerEmail', customerEmail)
      .set('FromDate', FromDate == undefined || FromDate == null || FromDate == '' ? '' : FromDate)
      .set('ToDate', ToDate == undefined || ToDate == null || ToDate == '' ? '' : ToDate)
      .set('UserViewType', userviewtype)
      .set('campaignId', campaignId)
      .set('ProspectLastName', ProspectLastName == undefined || ProspectLastName == null || ProspectLastName == '' ? '' : ProspectLastName)
      .set('ProspectPhone', ProspectPhone == undefined || ProspectPhone == null || ProspectPhone == '' ? '' : ProspectPhone)
      .set('ProspectEmail', ProspectEmail == undefined || ProspectEmail == null || ProspectEmail == '' ? '' : ProspectEmail)
      .set('Advisor', Advisor == undefined || Advisor == null || Advisor == '' ? '' : Advisor)
      .set('startrownumber', startrownumber)
      .set('endrownumber', endrownumber)
      .set('showArchive', showArchivedData)
      .set('Notes', noteSearch === undefined ? '' : noteSearch) // PCNG-2777
      .set('VenueName', venueSearch === undefined ? '' : venueSearch) // PCNG-2777

    return this.http.get(this.baseAPIurl + `api/leadsalesorderspagination`, { observe: 'response', params: param }).pipe(map(data => { return data.body }, catchError(this.handleError)));
  }


  // Error handling 
  handleError(error: { error: { message: string; }; status: any; message: any; }) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }
}

@Injectable({ providedIn: 'root' })
export class Eventservice {
  private baseAPIurl = '';
  private baseAPIurl2 = '';
  private prospectAPIurl = '';
  private uspsPostalStatement = ''
  // Default constructor invoked
  constructor(private http: HttpClient, private sharedService: SharedService) {
    this.baseAPIurl = sharedService.splitCurrentURL(apiurlName.eventApiEndPoint);
    this.baseAPIurl2 = sharedService.splitCurrentURL(apiurlName.responsesettingEventEditApiEndPoint);
    this.prospectAPIurl = sharedService.splitCurrentURL(apiurlName.dashboardProspectApiEndpoint);
    this.uspsPostalStatement = sharedService.splitCurrentURL(apiurlName.uspsPostalStatement)
  }
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    }),
  }

  public getConfigKeyValuePairs():Observable<any>{
    const param = new HttpParams()   
      .set('ApplicationKey','Prospects')    
      .set('ApplicationID','HUB')  
      return this.http.get(this.prospectAPIurl + `api/prospect/GetglobalKeyvalue`, {observe: 'response',params:param}).pipe(map(data => {                      
       return data.body},catchError(this.handleError)             
    )); 
  }

  // USPS Postal Statement Download
  getstatement(sowID: string): Observable<any> {
    return this.http.get(this.uspsPostalStatement + `api/uspscsvdata?SOWNumber=${sowID}`, { observe: 'response' }).pipe(map(data => {
      return data.body
    }, catchError(this.handleError)
    ));
  }

  geteventdetails(customerNumber: string, invoiceno: string, salesordernos: string): Observable<any> {
    const param = new HttpParams()
      //.set('customerid', customerid==null?"":sessionStorage.getItem('customerid'))
      .set('invoicenumber', invoiceno == null ? "" : invoiceno)
      .set('salesordernos', salesordernos == null ? "" : salesordernos);
    return this.http.get(this.baseAPIurl + `api/eventdetails/${customerNumber}`, { observe: 'response', params: param }).pipe(map(data => {
      return data.body
    }, catchError(this.handleError)
    ));
  }

  

  //event Advisor
  getAdvisors(CustomerEmail: string,): Observable<any> {

    return this.http.get<any>(this.baseAPIurl + `api/advisor/${CustomerEmail}`, { observe: 'response' }).pipe(map(data => {
      return data.body
    }, catchError(this.handleError)
    ));
  }
  //Lead advisor
  getLeadAdvisors(email_id: string): Observable<any> {
    const param = new HttpParams()
      .set('email_id', email_id);

    return this.http.get<any>(this.prospectAPIurl + `api/leadadvisor`, { observe: 'response', params: param }).pipe(map(data => {
      return data.body
    }, catchError(this.handleError)
    ));
  }

  getcampaigndetails(eventid: number): Observable<any> {
    const param = new HttpParams()
      .set('eventid', eventid == null ? "" : eventid)
    return this.http.get(this.baseAPIurl + 'api/EventCampaignDetails', { observe: 'response', params: param }).pipe(map(data => {
      return data.body
    }, catchError(this.handleError)
    ));
  }
  getstates(): Observable<any> {
    return this.http.get(this.baseAPIurl + 'api/states', { observe: 'response' }).pipe(map(data => {
      return data.body
    }, catchError(this.handleError)
    ));
  }
  geteventtype(): Observable<any> {
    return this.http.get(this.baseAPIurl + 'api/eventtypes', { observe: 'response' }).pipe(map(data => {
      return data.body
    }, catchError(this.handleError)
    ));
  }
  getmenutype(): Observable<any> {
    return this.http.get(this.baseAPIurl + 'api/eventmenuitems', { observe: 'response' }).pipe(map(data => {
      return data.body
    }, catchError(this.handleError)
    ));
  }
  geteventstatustype(): Observable<any> {
    return this.http.get(this.baseAPIurl + 'api/eventstatus', { observe: 'response' }).pipe(map(data => {
      return data.body
    }, catchError(this.handleError)
    ));
  }

  getconfirmationStatus(): Observable<any> {
    return this.http.get(this.baseAPIurl + 'api/remindercalldisposition', { observe: 'response' }).pipe(map(data => {
      return data.body
    }, catchError(this.handleError)
    ));
  }

  getrelationtypes(): Observable<any> {
    return this.http.get(this.baseAPIurl + 'api/relationtypes', { observe: 'response' }).pipe(map(data => {
      return data.body
    }, catchError(this.handleError)
    ));
  }

  getresponder(responderid: number, eventid: string): Observable<any> {
    const param = new HttpParams()
      .set('ResponderId', responderid == null || responderid == 0 ? "" : responderid)
      .set('EventIds', eventid == null || eventid == '0' ? "" : eventid);
    return this.http.get(this.baseAPIurl + 'api/Prospect_EventDetails', { observe: 'response', params: param }).pipe(map(data => {
      return data.body
    }, catchError(this.handleError)
    ));
  }


  GetCampaignEventDetails(CustomerEmail: string, salesordernos: string, fromDate: string, toDate: string, ProspectLastName: string, ProspectPhone: string, ProspectEmail: string, showArchivedData: boolean = true, searchNotes: string = '', searchVenue: string = ''): Observable<any> {
    const param = new HttpParams()
      .set('CustomerEmail', CustomerEmail == null ? "" : CustomerEmail)
      .set('SOWNo', salesordernos == null ? "" : salesordernos)
      .set('fromDate', fromDate).set('toDate', toDate)
      .set('ProspectLastName', ProspectLastName == undefined || ProspectLastName == null || ProspectLastName == '' ? '' : ProspectLastName)
      .set('ProspectPhone', ProspectPhone == undefined || ProspectPhone == null || ProspectPhone == '' ? '' : ProspectPhone)
      .set('ProspectEmail', ProspectEmail == undefined || ProspectEmail == null || ProspectEmail == '' ? '' : ProspectEmail)
      .set('showArchive', showArchivedData)
      .set('Notes', searchNotes === undefined ? '' : searchNotes) // PCNG-2777
      .set('VenueName', searchVenue === undefined ? '' : searchVenue) // PCNG-2777;
    return this.http.get(this.baseAPIurl + `api/CampaignEventDetails/`, { observe: 'response', params: param }).pipe(map(data => { return data.body }, catchError(this.handleError)));
  }


  sendNewEventmail(Name, campaignphone, Accountname, SOW, customerNumber, CampaignName, CampaignType, RSVPCode
    , EventDate, EventTime, Location, EventType, maximumroomcapacity): Observable<any> {

    const param = new HttpParams()
      .set('Name', Name)
      .set('campaignphone', campaignphone == null ? "" : campaignphone)
      .set('Accountname', Accountname == null ? "" : Accountname)
      .set('SOW', SOW == null ? "" : SOW)
      .set('CustomerNumber', customerNumber)
      .set("CampaignName", CampaignName == null ? "" : CampaignName)
      .set("CampaignType", CampaignType == null ? "" : CampaignType)
      .set("RSVPCode", RSVPCode == null ? "" : RSVPCode)
      .set("EventDate", EventDate)
      .set("EventTime", EventTime)
      .set("Location", Location == null ? "" : Location)
      .set("EventType", EventType)
      .set("maximumroomcapacity", maximumroomcapacity)

    return this.http.get(this.baseAPIurl + 'api/sendNewEventmail', { observe: 'response', params: param }).pipe(map(data => {
      return data.body
    }, catchError(this.handleError)
    ));
  }

  addNewEvent(selectedEvent:any, eventData:any): Observable<any> {
    const updateUser = sessionStorage.getItem('loggeduser');
    const param = new HttpParams()
      .set('serviceCallSource', 'Hub')
      .set('updateUser', updateUser)
      .set('campaignId', selectedEvent.campaignId)
      .set('rsvpCode',selectedEvent.primaryaccountordernumber)
      .set('campaignName', selectedEvent.campaignName)
      .set("campaignType", selectedEvent.clientCampaignType)
      .set("eventId", selectedEvent.eventid)
      .set("eventTypeId", eventData.event_type)
      .set("eventDate", moment(eventData.calendar_date).format('YYYY-MM-DD'))
      .set("eventTime", eventData.time)
      .set("amOrPM", eventData.selectedAmPm)
      .set("maxCapacity", eventData.room_capacity)
      .set("maxPerParty", eventData.max_per_party)
      .set("allowReservationsUntilHoursBefore", eventData.hours_before)
      .set("venueId", selectedEvent.venueId)
      .set("venueName", selectedEvent.venueName)
      .set("venueCity", selectedEvent.city)
      .set("venueState", selectedEvent.stateName)
      .set("venueZip", selectedEvent.zip)

    return this.http.get(this.baseAPIurl + 'api/copyEvent', { observe: 'response', params: param }).pipe(map(data => {
      return data.body
    }, catchError(this.handleError)
    ));
  }

  GetAddEventDetails(CustomerEmail, SOWNum, FromDate, ToDate): Observable<any> {

    const param = new HttpParams()
      .set('CustomerEmail', CustomerEmail)
      .set('SOWNum', SOWNum == null ? "" : SOWNum)
      .set('FromDate', FromDate)
      .set('ToDate', ToDate)

    return this.http.get(this.baseAPIurl + 'api/AddEventDetails', { observe: 'response', params: param }).pipe(map(data => {
      return data.body
    }, catchError(this.handleError)
    ));
  }

  getmarkerdetails(customerEmail: string, fromDate: string, toDate: string, advisor: string = null, EventId, StartRowNum: number, EndRowNum: number, ProspectLastName: string, ProspectPhone: string, ProspectEmail: string, searchNotes: string = '', searchVenue: string = '') {
    const param = new HttpParams()
      .set('fromDate', fromDate)
      .set('toDate', toDate)
      .set('advisor', advisor == 'All' || advisor == null ? '' : advisor)
      .set('EventId', EventId)
      .set('StartRowNum', StartRowNum)
      .set('EndRowNum', EndRowNum)
      .set('ProspectLastName', ProspectLastName == undefined || ProspectLastName == null || ProspectLastName == '' ? '' : ProspectLastName)
      .set('ProspectPhone', ProspectPhone == undefined || ProspectPhone == null || ProspectPhone == '' ? '' : ProspectPhone)
      .set('ProspectEmail', ProspectEmail == undefined || ProspectEmail == null || ProspectEmail == '' ? '' : ProspectEmail)
      .set('Notes', searchNotes === undefined ? '' : searchNotes) // PCNG-2777
      .set('VenueName', searchVenue === undefined ? '' : searchVenue); // PCNG-2777
    return this.http.get(this.baseAPIurl + `api/markerdetails/${customerEmail}`, { observe: 'response', params: param }).pipe(map(data => { return data.body }, catchError(this.handleError))).toPromise();
  }

  getMarkerDetailsLP(eventID) {
    const param = new HttpParams().set('EventId', eventID);
    // return this.http.get(this.baseAPIurl + `api/landingpagemarkerdetails`,
    return this.http.get(this.baseAPIurl + `api/simplemarkerdetails`,
      { observe: 'response', params: param }).pipe(map(data => { return data.body },
      catchError(this.handleError))).toPromise();
  }

  getEventDetailsFromID(eventID) {
    const param = new HttpParams().set('eventid', eventID);
    return this.http.get(this.baseAPIurl + `api/eventdetails`,
      { observe: 'response', params: param }).pipe(map(data => { return data.body },
      catchError(this.handleError))).toPromise();
  }


  geteditresponder(responderid: number, eventid: string): Observable<any> {
    const param = new HttpParams()
      .set('ResponderId', responderid == null || responderid == 0 ? "" : responderid)
      .set('EventIds', eventid == null || eventid == '0' ? "" : eventid);
    return this.http.get(this.baseAPIurl + 'api/editprospectdetails', { observe: 'response', params: param }).pipe(map(data => {
      return data.body
    }, catchError(this.handleError)
    ));
  }
  getresponderview(responderid: number): Observable<any> {
    const param = new HttpParams()
      .set('ResponderId', responderid == null || responderid == 0 ? "" : responderid)
    return this.http.get(this.baseAPIurl + 'api/view_prospectdetails', { observe: 'response', params: param }).pipe(map(data => {
      return data.body
    }, catchError(this.handleError)
    ));
  }
  getevent(customerNumber: string, invoices: string, salesordernos: string): Observable<any> {
    const param = new HttpParams()
      .set('invoices', invoices == null ? "" : invoices)
      .set('salesordernos', salesordernos == null ? "" : salesordernos);
    return this.http.get(this.baseAPIurl + `api/events/${customerNumber}`, { observe: 'response', params: param }).pipe(map(data => {
      return data.body
    }, catchError(this.handleError)
    ));
  }

  getallevent(customerEmail: string): Observable<any> {

    return this.http.get(this.baseAPIurl + `api/allevents/${customerEmail}`, { observe: 'response' }).pipe(map(data => {
      return data.body
    }, catchError(this.handleError)
    ));
  }
  getallpastfutureevent(customerEmail: string, advisor: string = null, fromDate: string = null, toDate: string = null, showArchivedData:boolean = true): Observable<any> {
    const param = new HttpParams()
      .set('advisor', advisor == 'All' || advisor == null ? '' : advisor)
      .set('fromDate', fromDate)
      .set('toDate', toDate)
      .set('showArchive', showArchivedData);
    return this.http.get(this.baseAPIurl + `api/allpastfutureevents/${customerEmail}`, { observe: 'response', params: param }).pipe(map(data => {
      return data.body
    }, catchError(this.handleError)
    ));
  }

  getfullcalandarevent(customerEmail: string, advisor: string = null, fromDate: string = null, toDate: string = null, showArchivedData:boolean = true): Observable<any> {

    const param = new HttpParams()
      .set('advisor', advisor == 'All' || advisor == null ? '' : advisor)
      .set('fromDate', fromDate)
      .set('toDate', toDate)
      .set('showArchive', showArchivedData);
    return this.http.get(this.baseAPIurl + `api/fullcalandarevent/${customerEmail}`, { observe: 'response', params: param }).pipe(map(data => {
      return data.body
    }, catchError(this.handleError)
    ));
  }

  getRegisteredEventDetails(responderId: number): Observable<any> {
    return this.http.get(this.baseAPIurl + `api/event/${responderId}`, { observe: 'response' }).pipe(map(data => {
      return data.body
    }, catchError(this.handleError)
    ));
  }

  getPrintSeminarRoaster(ReportType: string, eventid: string): Observable<any> {
    const param = new HttpParams()
      .set('ReportType', ReportType == null ? "" : ReportType)
      .set('eventid', eventid == null || eventid == '0' ? "" : eventid);
    console.log("param:" + param);
    return this.http.get(this.baseAPIurl + `api/printseminarroaster`, { observe: 'response', params: param })
      .pipe(map(data => {
        return data.body
      }, catchError(this.handleError)
      ));
  }

  getEventReports(ReportType: string, eventid: string): Observable<any> {
    const param = new HttpParams()
      .set('ReportType', ReportType == null ? "" : ReportType)
      .set('eventid', eventid == null || eventid == '0' ? "" : eventid);

    return this.http.get(this.baseAPIurl + `api/eventreportdetails`, { params: param, responseType: 'blob' as 'json' });
  }

  getEventReportdetails(ReportType: string, eventid: string): Observable<any> {
    const param = new HttpParams()
      .set('ReportType', ReportType == null ? "" : ReportType)
      .set('eventid', eventid == null || eventid == '0' ? "" : eventid);

    return this.http.get(this.baseAPIurl + 'api/eventreportdetails', { observe: 'response', params: param }).pipe(map(data => {
      return data.body
    }, catchError(this.handleError)
    ));
  }

  getProspectNameReportdetails(ReportType: string, eventid: string): Observable<any> {
    const param = new HttpParams()
      .set('ReportType', ReportType == null ? "" : ReportType)
      .set('eventid', eventid == null || eventid == '0' ? "" : eventid);

    return this.http.get(this.baseAPIurl + 'api/printnametagsdtls', { observe: 'response', params: param }).pipe(map(data => {
      return data.body
    }, catchError(this.handleError)
    ));
  }

  submitWaitList(action, responderid, EventId, WaitList) {
    var params = {
      "action": action,
      "prospectId": responderid,
      "eventId": EventId,
      "waitlist": WaitList
    }
    return this.http.post(this.baseAPIurl2 + 'api/event/edit', params, this.httpOptions).pipe(map(data => {

    }, catchError(this.handleError)
    ));
    //    this.http.post(this.baseAPIurl2 + 'api/event/edit',
    //    params, this.httpOptions
    //  )
    //    .subscribe(data => {

    //    },
    //      error => { alert("Error"); }
    //    );
  }

  submitVerified(action, responderid, EventId, Verified) {
    var params = {
      "action": "verify",
      "prospectId": responderid,
      "eventId": EventId,
      "verified": Verified
    }
    return this.http.post(this.baseAPIurl2 + 'api/event/edit', params, this.httpOptions).pipe(map(data => {

    }, catchError(this.handleError)
    ));
    //    this.http.post(this.baseAPIurl2 + 'api/event/edit',
    //    params, this.httpOptions
    //  )
    //    .subscribe(data => {

    //    },
    //      error => { alert("Error"); }
    //    );
  }

  submitDisqualify(action, responderid, EventId, Disqualified) {
    var params = {
      "action": action,
      "prospectId": responderid,
      "eventId": EventId,
      "disqualify": Disqualified
    }
    return this.http.post(this.baseAPIurl2 + 'api/event/edit', params, this.httpOptions).pipe(map(data => {

    }, catchError(this.handleError)
    ));
    //    this.http.post(this.baseAPIurl2 + 'api/event/edit',
    //    params, this.httpOptions
    //  )
    //    .subscribe(data => {

    //    },
    //      error => { alert("Error"); }
    //    );
  }
  submitOkToContact(action, responderid, EventId, OkToContact) {
    var params = {
      "action": action,
      "prospectId": responderid,
      "eventId": EventId,
      "OkToContact": OkToContact

    }
    return this.http.post(this.baseAPIurl2 + 'api/event/edit', params, this.httpOptions).pipe(map(data => {

    }, catchError(this.handleError)
    ));
    //           this.http.post(this.baseAPIurl2 + 'api/event/edit',
    //    params, this.httpOptions
    //  )
    //    .subscribe(data => {

    //    },
    //      error => { alert("Error"); }
    //    );
  }

  submitCancelled(action, responderid, EventId, Cancelled, IsGuest, interestedInFutureEvent:boolean= false) {
    var params = {
      "action": action,
      "prospectId": responderid,
      "eventId": EventId,
      "cancelled": Cancelled,
      "IsGuest": IsGuest,
      "MoveToFutureEvent": interestedInFutureEvent ? 1 : 0
    }

    return this.http.post(this.baseAPIurl2 + 'api/event/edit', params, this.httpOptions).pipe(map(data => {

    }, catchError(this.handleError)
    ));

    //    this.http.post(this.baseAPIurl2 + 'api/event/edit',
    //    params, this.httpOptions
    //  )
    //    .subscribe(data => {

    //    },
    //      error => { alert("Error"); }
    //    );
  }

  submitInterestedInFutureEvents(action, responderid, EventId, FutureEvent) {
    var params = {
      "action": action,
      "prospectId": responderid,
      "eventId": EventId,
      "interestedinfuture": FutureEvent
    }
    return this.http.post(this.baseAPIurl2 + 'api/event/edit', params, this.httpOptions).pipe(map(data => {

    }, catchError(this.handleError)
    ));
    //    this.http.post(this.baseAPIurl2 + 'api/event/edit',
    //    params, this.httpOptions
    //  )
    //    .subscribe(data => {

    //    },
    //      error => { alert("Error"); }
    //    );
  }

  submitCheckIn(action, responderid, EventId, CheckIn, IsGuest) {
    var params = {
      "action": action,
      "prospectId": responderid,
      "eventId": EventId,
      "checkin": CheckIn,
      "IsGuest": IsGuest
    }
    return this.http.post(this.baseAPIurl2 + 'api/event/edit', params, this.httpOptions).pipe(map(data => {

    }, catchError(this.handleError)
    ));

  }

  submitBulkAction(action, prospectID, eventID, isGuest) {
    var params = {
      "action": action,
      "prospectList": prospectID,
      "eventId": eventID,
      "IsGuest": isGuest
    }
    if (action === "checkinall") params["checkin"] = 1;
    else if (action === "cancelall") params["cancelled"] = 1;
    return this.http.post(this.baseAPIurl2 + 'api/event/edit', params, this.httpOptions).pipe(map(data => {
    }, catchError(this.handleError)
    ));
  }


  submitEditProspectDetails(action, responderid, FirstName, LastName, AddressLine1, AddressLine2, City, SelectedStateId, PrimaryEmail
    , AlternateEmail, primaryphone, AlternatePhone, Zip, selectedProspectEventId, selecteventid, guestEventId, guestDetails, IsPhoneFollowUp, IsCopyguestevent) {
      /** PCNG-2104 added logged-in user email address for all update prospects */ 
      const UpdateUser = sessionStorage.getItem('loggeduser');

    var params = {
      "action": action,
      "prospectId": responderid,
      "firstName": FirstName,
      "lastName": LastName,
      "AddressLine1": AddressLine1,
      "AddressLine2": AddressLine2,
      "City": City,
      "StateId": SelectedStateId,
      "PrimaryEmail": PrimaryEmail,
      "AlternateEmail": AlternateEmail,
      "PrimaryPhone": primaryphone,
      "AlternatePhone": AlternatePhone,
      "Zip": Zip,
      "EventId": selecteventid,
      "OldEventId": selectedProspectEventId,
      "GuestEventId": guestEventId,
      "GuestDetails": guestDetails,
      "IsPhoneFollowUp": IsPhoneFollowUp,
      "IsCopyguestevent": IsCopyguestevent,
       "UpdateUser": UpdateUser
    }


    return this.http.post(this.baseAPIurl2 + 'api/event/edit', params, this.httpOptions).pipe(map(data => {
    }, catchError(this.handleError)
    ));
  }

  AddClientProspect(CampaignId, InsertionOrder, eventId, selEventMenuList, DoNotContact, EmailOptIn, TextOptIn,
    FirstName, LastName, AddressLine1, AddressLine2, City,
    selectedState, Zip, phone, PrimaryEmail, PhoneTypeSel,guestDetails): Observable<any> {

    /** PCNG-2104 added logged-in user email address for all Add Client prospects */  
    const UpdateUser = sessionStorage.getItem('loggeduser');

    const param = new HttpParams()
      .set('CampaignId', CampaignId == null ? 0 : CampaignId )
      .set("Code", InsertionOrder == null ? '' : InsertionOrder)
      .set("EventId", eventId == null ? 0 : eventId)
      .set("MenuSelection", selEventMenuList == null ? 0 : selEventMenuList)
      .set("PhoneOptIn", DoNotContact == null ? '' : DoNotContact)
      .set("EmailOptIn", EmailOptIn == null ? '' : EmailOptIn)
      .set("TextOptIn", TextOptIn == null ? '' : TextOptIn)
      .set("FirstName", FirstName == null ? '' : FirstName)
      .set("LastName", LastName == null ? '' : LastName)
      .set("Address1", AddressLine1 == null ? '' : AddressLine1)
      .set("Address2", AddressLine2 == null ? '' : AddressLine2)
      .set("City", City == null ? '' : City)
      .set("State", selectedState == null ? '' : selectedState)
      .set("Zip", Zip == null ? '' : Zip)
      .set("Phone", phone == null ? '' : phone)
      .set("Email", PrimaryEmail == null ? '' : PrimaryEmail)
      .set("PhoneType", PhoneTypeSel == null ? '' : PhoneTypeSel)
      .set("guestDetails",guestDetails)
      .set("UpdateUser",UpdateUser)
    return this.http.get(this.baseAPIurl + 'api/AddClientProspect', { observe: 'response', params: param }).pipe(map(data => {
      return data.body
    }, catchError(this.handleError)
    ));
  }

  SendTextMessage(ResponderId: string, RespPhoneNumber: string, TextMessage: string, CustomerId: number, customerNumber: string): Observable<any> {
    const param = new HttpParams()
      .set('RespondrId', ResponderId == null ? 0 : ResponderId)
      .set('ResponderPhoneNumber', RespPhoneNumber == null || RespPhoneNumber == '0' ? "" : RespPhoneNumber)
      .set('Message', TextMessage == null || TextMessage == '0' ? "" : TextMessage)
      .set('CustomerId', 0)
      .set('CustomerNumber', customerNumber == null ? "" : customerNumber);
    return this.http.get(this.baseAPIurl + 'api/SendProspectMessage', { observe: 'response', params: param }).pipe(map(data => {
      return data.body
    }, catchError(this.handleError)
    ));
  }

  SendTextMail(ClientId: number, leadId: number, FromMailId: string, FromMailName: string, MailType: string, ToMailIds: string, Subject: string, Body: string, ResponderId: number, EventId: number, CampaignId: number): Observable<any> {
    const param = new HttpParams()
      .set('FromMailId', FromMailId == null || FromMailId == '0' ? "" : FromMailId)
      .set('FromMailName', FromMailName == null || FromMailName == '0' ? "" : FromMailName)
      .set('MailType', MailType == null || MailType == '0' ? "" : MailType)
      .set('ToMailIds', ToMailIds == null || ToMailIds == '0' ? "" : ToMailIds)
      .set('Subject', Subject == null || Subject == '0' ? "" : Subject)
      .set('Body', Body == null || Body == '0' ? "" : Body)
      .set('ClientId', ClientId == null || ClientId == 0 ? "" : ClientId)
      .set('ResponderId', ResponderId == null || ResponderId == 0 ? "" : ResponderId)
      .set('leadId', leadId == null || leadId == 0 ? "" : leadId)
      .set('EventId', EventId == null || EventId == 0 ? "" : EventId)
      .set('CampaignId', CampaignId == null || CampaignId == 0 ? "" : CampaignId);
    return this.http.get(this.baseAPIurl + 'api/sendProspectmail', { observe: 'response', params: param }).pipe(map(data => {
      return data.body
    }, catchError(this.handleError)
    ));
  }

  submitEventDetails(action, item) {

    const customerEmail = sessionStorage.getItem('contactemail');
    const customerName = sessionStorage.getItem('name');

    var params = {
      "action": action,
      "Maximumroomcapacity": item.maximumRoomCapacity,
      "MaximumPerParty": item.maximumPerParty,
      "AllowReservationUntil": item.allowReservationsUntilHoursBefore,
      "EventStatusId": item.eventStatusid,
      "EventId": item.eventid,
      "EventOrganizerName": item.eventOrganizerName ? item.eventOrganizerName : '',
      "EventOrganizerEmail": item.eventOrganizerEmail ? item.eventOrganizerEmail : '',
      "EventOrganizerPhone": item.eventOrganizerPhone ? item.eventOrganizerPhone : '',
      "EventCoordinatorName": item.venueCoordinatorName ? item.venueCoordinatorName : '',
      "EventCoordinatorEmail": item.venueCoordinatorEmail ? item.venueCoordinatorEmail : '',
      "HUBUserName": customerName,
      "HUBUserEmail": customerEmail,
      "VenueId": item.venueId
    }
    return this.http.post(this.baseAPIurl2 + 'api/event/edit', params, this.httpOptions).pipe(map(data => {
      return data;
    }, catchError(this.handleError)
    ));
  }
  updateNotes(action, ResponderNoteId, responderid, Description, IsDeleted, getdatetime) {
    var params = {
      "action": action,
      "ResponderNoteId": ResponderNoteId,
      "prospectId": responderid,
      "Description": Description,
      "IsDeleted": IsDeleted,
      "GetDateTime": getdatetime
    }
    return this.http.post(this.baseAPIurl2 + 'api/event/edit', params, this.httpOptions).pipe(map(data => {
    }, catchError(this.handleError)
    ));
  }

  getEventResponderCount(EventId: number, ResponderStatusTypeId: number, isIncludeGuests: boolean): Observable<any> {
    var params = {
      "EventId": EventId,
      "ResponderStatusTypeId": ResponderStatusTypeId,
      "isIncludeGuests": isIncludeGuests
    }
    return this.http.get(this.baseAPIurl + 'api/eventrespondercount', { observe: 'response', params: params }).pipe(map(data => {
      return data.body
    }, catchError(this.handleError)
    ));
  }

  upsertReminderCall(action: string, ReminderCallId: number, ResponderId: number, EventId: number, ReminderCallDispositionId: number, CallDate: string, isDeleted: boolean): Observable<any> {
    var params = {
      "action": action,
      "ReminderCallId": ReminderCallId,
      "ResponderId": ResponderId,
      "EventId": EventId,
      "ReminderCallDispositionId": ReminderCallDispositionId,
      "CallDate": CallDate,
      "isDeleted": isDeleted
    }
    return this.http.post(this.baseAPIurl2 + 'api/event/edit', params, this.httpOptions).pipe(map(data => {

    }, catchError(this.handleError)
    ));
  }

  getEventMenuItem(EventIds: string): Observable<any> {
    var params = {
      "eventids": EventIds
    }
    return this.http.get(this.baseAPIurl + `api/eventmenuitem/`, { observe: 'response', params: params }).pipe(map(data => {
      return data.body
    }, catchError(this.handleError)
    ));
  }

  saveProspectMenuItem(action, Type, ResponderId, GuestId, EventId, MenuItemId) {
    var params = {
      "action": action,
      "Type": Type,
      "ResponderId": ResponderId,
      "GuestId": GuestId,
      "EventId": EventId,
      "MenuItemId": MenuItemId
    }
    return this.http.post(this.baseAPIurl2 + 'api/event/edit', params, this.httpOptions).pipe(map(data => {

    }, catchError(this.handleError)
    ));
  }
  saveArchivalData(archiveParams:ArchiveParams) {
    var params = {
      "campaignID": archiveParams.campaignID,
      "eventID": archiveParams.eventID,
      "isArchive": archiveParams.isArchive,
      "loggedInUser": archiveParams.loggedInUser,
      "impersonatedUser": archiveParams.impersonatedUser
    }
    return this.http.post(this.baseAPIurl + 'api/DataArchive', params, this.httpOptions).pipe(map(data => {
      return data;
    }, catchError(this.handleError)
    ));
  }
  //LeadReports




  //Error handling
  handleError(error: { error: { message: string; }; status: any; message: any; }) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    }
    else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }
}

@Injectable({ providedIn: 'root' })
export class Leadservice {
  private baseAPIurl = '';
  private baseAPIurl1 = '';
  private baseAPIurl2 = '';
  // Default constructor invoked
  constructor(private http: HttpClient, private sharedService: SharedService) {
    this.baseAPIurl = sharedService.splitCurrentURL(apiurlName.dashboardProspectApiEndpoint);
    this.baseAPIurl1 = sharedService.splitCurrentURL(apiurlName.eventApiEndPoint);
    this.baseAPIurl2 = sharedService.splitCurrentURL(apiurlName.responsesettingEventEditApiEndPoint);
  }
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    }),
  }
  //LeadReports
  public getLeadReports(ReportType: string, InsertionOrder, listofclientIds): Observable<any> {

    const param = new HttpParams()
      //.set('customerId', customerId==null || customerId=='0'?"":customerId)
      .set('ReportType', ReportType == null ? "" : ReportType)
      .set('InvoiceId', InsertionOrder == null ? "" : InsertionOrder)
      .set('ClientId', listofclientIds == null ? "" : listofclientIds);
    return this.http.get(this.baseAPIurl + `api/leadreportdetails`, { params: param, responseType: 'blob' as 'json' });
  }

  public getPracticeAreaColor(clientId: string, invoice: string, FromDate, ToDate, ProspectLastName: string, ProspectPhone: string, ProspectEmail: string): Observable<any> {
    const param = new HttpParams()
      .set('clientId', clientId == null ? "" : clientId)
      .set('invoice', invoice == null ? "" : invoice)
      .set('FromDate', FromDate == undefined || FromDate == null || FromDate == '' ? '' : FromDate)
      .set('ToDate', ToDate == undefined || ToDate == null || ToDate == '' ? '' : ToDate)
      .set('ProspectLastName', ProspectLastName == undefined || ProspectLastName == null || ProspectLastName == '' ? '' : ProspectLastName)
      .set('ProspectPhone', ProspectPhone == undefined || ProspectPhone == null || ProspectPhone == '' ? '' : ProspectPhone)
      .set('ProspectEmail', ProspectEmail == undefined || ProspectEmail == null || ProspectEmail == '' ? '' : ProspectEmail)
    return this.http.get(this.baseAPIurl + `api/leadpracticeareawithcolor`, { observe: 'response', params: param }).pipe(map(data => {
      return data.body
    }, catchError(this.handleError)
    ));
  }
  public getLeadsOrderTableDetails(clientId: string, practiceareaid: string = null, invoice: string, salesorder: string, FromDate: string, ToDate: string, ProspectLastName: string, ProspectPhone: string, ProspectEmail: string): Observable<any> {
    const param = new HttpParams()
      .set('clientId', clientId == null ? "" : clientId)
      .set('practiceareaid', practiceareaid == null ? "" : practiceareaid)
      .set('invoice', invoice == null ? "" : invoice)
      .set('salesorder', salesorder == null ? "" : salesorder)
      .set('FromDate', FromDate == undefined || FromDate == null || FromDate == '' ? '' : FromDate)
      .set('ToDate', ToDate == undefined || ToDate == null || ToDate == '' ? '' : ToDate)
      .set('ProspectLastName', ProspectLastName == undefined || ProspectLastName == null || ProspectLastName == '' ? '' : ProspectLastName)
      .set('ProspectPhone', ProspectPhone == undefined || ProspectPhone == null || ProspectPhone == '' ? '' : ProspectPhone)
      .set('ProspectEmail', ProspectEmail == undefined || ProspectEmail == null || ProspectEmail == '' ? '' : ProspectEmail)
    return this.http.get(this.baseAPIurl + `api/leadorderdata`, { observe: 'response', params: param }).pipe(map(data => {
      return data.body
    }, catchError(this.handleError)
    ));
  }
  public getLeadsfilterdata(invoicenumber: number): Observable<any> {
    return this.http.get(this.baseAPIurl + `api/leadorderdata`, { observe: 'response' }).pipe(map(data => {
      return data.body
    }, catchError(this.handleError)
    ));
  }
  public getLeadQuestionnaireData(leadId: string): Observable<any> {
    const param = new HttpParams()
      .set('leadId', leadId == null ? "" : leadId);
    return this.http.get(this.baseAPIurl + `api/leadsquestionnaire`, { observe: 'response', params: param }).pipe(map(data => {
      return data.body
    }, catchError(this.handleError)
    ));
  }

  public getLeadsDemographic(leadId: string): Observable<any> {
    const param = new HttpParams()
      .set('LeadId', leadId == null ? "" : leadId);
    return this.http.get(this.baseAPIurl + `api/leadsdemographics`, { observe: 'response', params: param }).pipe(map(data => {
      return data.body
    }, catchError(this.handleError)
    ));
  }

  public getleadInvoiceDetails(customerNumber: string, customerEmail: string): Observable<any> {
    const param = new HttpParams()
      .set('customerNumber', customerNumber)
      .set('customerEmail', customerEmail);
    return this.http.get(this.baseAPIurl + `api/leadinvoicedetails`, { observe: 'response', params: param }).pipe(map(data => {
      return data.body
    }, catchError(this.handleError)
    ));
  }

  getLeadStates(): Observable<any> {

    return this.http.get(this.baseAPIurl + `api/leadsStates`, { observe: 'response' }).pipe(map(data => {
      return data.body
    }, catchError(this.handleError)
    ));
  }

  getLeadInfoByLeadID(leadId: number): Observable<any> {
    const param = new HttpParams()
      .set('leadId', leadId == null ? "" : leadId);
    // console.log("param:"+param);
    return this.http.get(this.baseAPIurl + `api/leadinfo`, { observe: 'response', params: param }).pipe(map(data => {
      return data.body
    }, catchError(this.handleError)
    ));
  }

  updateLeadNotes(action, LeadNoteId, leadId, Description, IsDeleted, getdatetime) {

    var params = {
      "action": action,
      "LeadNoteId": LeadNoteId,
      "LeadId": leadId,
      "Description": Description,
      "IsDeleted": IsDeleted,
      "GetDateTime": getdatetime
    }
    return this.http.post(this.baseAPIurl2 + 'api/prospect/edit', params, this.httpOptions).pipe(map(data => {
    }, catchError(this.handleError)
    ));
  }

  submitEditLeadProspectDetails(action, leadId, FirstName, LastName, AddressLine1, AddressLine2, City, SelectedLeadStateId, PrimaryEmail
    , AlternateEmail, primaryphone, AlternatePhone, Zip, IsPhoneFollowUp) {
    var params = {
      "action": action,
      "leadId": leadId,
      "firstName": FirstName,
      "lastName": LastName,
      "AddressLine1": AddressLine1,
      "AddressLine2": AddressLine2,
      "City": City,
      "StateId": SelectedLeadStateId,
      "PrimaryEmail": PrimaryEmail,
      "AlternateEmail": AlternateEmail,
      "PrimaryPhone": primaryphone,
      "AlternatePhone": AlternatePhone,
      "Zip": Zip,
      "IsPhoneFollowUp": IsPhoneFollowUp
    }
    return this.http.post(this.baseAPIurl2 + 'api/prospect/edit', params, this.httpOptions).pipe(map(data => {
    }, catchError(this.handleError)
    ));
  }

  //Error handling
  handleError(error: {
    error: { message: string };
    status: any;
    message: any;
  }) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }


}


//************************************************************************************************/
// ProspectChartService - used to retrieve chart data.
//************************************************************************************************/
@Injectable({ providedIn: "root" })
export class ProspectChartService {

  public prospectroutes: ProspectList[];
  private baseAPIurl = "";

  // Default constructor invoked
  constructor(
    private http: HttpClient,
    private sharedService: SharedService
  ) {
    this.baseAPIurl = sharedService.splitCurrentURL(
      apiurlName.dashboardProspectApiEndpoint
    );
  }

  getProspectCharts(
    campaignID: string = "1",
    campaignGroupID: string = "1",
    eventID: string = null
  ): Observable<any> {
    let param;
    if (eventID !== null) {
      param = new HttpParams().set(
        "EventId",
        eventID == null ? "" : eventID
      );
    } else {
      param = new HttpParams().set(
        "CampaignId",
        campaignID == null ? "" : campaignID
      );
    }
    return this.http
      .get(
        this.baseAPIurl +
        `api/prospect/chartordersandevents`,
        { observe: "response", params: param }
      )
      .pipe(
        map((data) => {
          return data.body;
        }, catchError(this.handleError))
      );
  }
  // Error handling
  handleError(error: {
    error: { message: string };
    status: any;
    message: any;
  }) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }
}
//************************************************************************************************/
