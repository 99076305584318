import { Injectable } from "@angular/core";
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
} from "@angular/router";
import { Observable } from "rxjs/internal/Observable";
import { AuthService } from "../services/auth.service";
import { SharedService } from "../services/shared.service";
import { Router } from "@angular/router";

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private auth: AuthService, private myRoute: Router) {}

    validateRoute(route) {
        switch (route._routerState.url) {
            case "/":
                return true;
            case "/home":
                return true;
            case "/sample":
                return true;
            case "/dashboard":
                return true;
            case "/sales-order":
                return true;
            case "/message-history":
                return true;
            case "/account-setting":
                return true;
            case "/exports-integration":
                return true;
            case "/response-settings":
                return true;
            case "/performance-analysis":
                return true;
            case "/campaigns":
                return true;
            case "/activities":
                return true;
            case "/prospects":
                return true;
            case "/dashboard2":
                return true;
            case "/tutorials":
                return true;
            case "/impersonate-contact":
                const loggedUser = sessionStorage.getItem("loggeduser");
                return loggedUser && loggedUser.includes("@leadingresponse");
            default:
                return false;
        }
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        if (this.validateRoute(next)) {
            return true;
        } else {
            this.myRoute.navigate(["./access-denied"]);
            return false;
        }
    }
}
