import { apiurlName, SharedService } from './../../../../shared/services/shared.service';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import * as internal from 'stream';


@Injectable({ providedIn: 'root' })
export class Eventservice {
  private baseAPIurl = '';
  private baseAPIurl2 = '';
  private prospectAPIurl = '';
  // Default constructor invoked
  constructor(private http: HttpClient, private sharedService: SharedService) {
    this.baseAPIurl = sharedService.splitCurrentURL(apiurlName.eventApiEndPoint);
    this.baseAPIurl2 = sharedService.splitCurrentURL(apiurlName.responsesettingEventEditApiEndPoint);
    this.prospectAPIurl = sharedService.splitCurrentURL(apiurlName.dashboardProspectApiEndpoint);
  }
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    }),
  }

  public getConfigKeyValuePairs():Observable<any>{
    const param = new HttpParams()   
      .set('ApplicationKey','Prospects')    
      .set('ApplicationID','HUB')  
      return this.http.get(this.prospectAPIurl + `api/prospect/GetglobalKeyvalue`, {observe: 'response',params:param}).pipe(map(data => {                      
       return data.body},catchError(this.handleError)             
    )); 
  }

  getresponderview(responderid: number): Observable<any> {
    const param = new HttpParams()
      .set('ResponderId', responderid == null || responderid == 0 ? "" : responderid)
    return this.http.get(this.baseAPIurl + 'api/view_prospectdetails', { observe: 'response', params: param }).pipe(map(data => {
      return data.body
    }, catchError(this.handleError)
    ));
  }

  updateNotes(action, ResponderNoteId, responderid, Description, IsDeleted, getdatetime) {
    var params = {
      "action": action,
      "ResponderNoteId": ResponderNoteId,
      "prospectId": responderid,
      "Description": Description,
      "IsDeleted": IsDeleted,
      "GetDateTime": getdatetime
    }
    return this.http.post(this.baseAPIurl2 + 'api/event/edit', params, this.httpOptions).pipe(map(data => {
    }, catchError(this.handleError)
    ));
  }

  //Error handling
  handleError(error: { error: { message: string; }; status: any; message: any; }) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    }
    else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }
}





