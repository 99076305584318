import { apiurlName, SharedService } from './../../../shared/services/shared.service';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import * as internal from 'stream';

@Injectable({ providedIn: 'root' })
export class Eventservice {
  private baseAPIurl = '';
  private baseAPIurl2 = '';
  private prospectAPIurl = '';
  // Default constructor invoked
  constructor(private http: HttpClient, private sharedService: SharedService) {
    this.baseAPIurl = sharedService.splitCurrentURL(apiurlName.eventApiEndPoint);
    this.baseAPIurl2 = sharedService.splitCurrentURL(apiurlName.responsesettingEventEditApiEndPoint);
    this.prospectAPIurl = sharedService.splitCurrentURL(apiurlName.dashboardProspectApiEndpoint);
  }
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    }),
  }

  public getConfigKeyValuePairs():Observable<any>{
    const param = new HttpParams()   
      .set('ApplicationKey','Prospects')    
      .set('ApplicationID','HUB')  
      return this.http.get(this.prospectAPIurl + `api/prospect/GetglobalKeyvalue`, {observe: 'response',params:param}).pipe(map(data => {                      
       return data.body},catchError(this.handleError)             
    )); 
  }
 
  getconfirmationStatus(): Observable<any> {
    return this.http.get(this.baseAPIurl + 'api/remindercalldisposition', { observe: 'response' }).pipe(map(data => {
      return data.body
    }, catchError(this.handleError)
    ));
  }

  getrelationtypes(): Observable<any> {
    return this.http.get(this.baseAPIurl + 'api/relationtypes', { observe: 'response' }).pipe(map(data => {
      return data.body
    }, catchError(this.handleError)
    ));
  }

  getMarkerDetailsLP(eventID) {
    const param = new HttpParams().set('EventId', eventID);
    // return this.http.get(this.baseAPIurl + `api/landingpagemarkerdetails`,
    return this.http.get(this.baseAPIurl + `api/simplemarkerdetails`,
      { observe: 'response', params: param }).pipe(map(data => { return data.body },
      catchError(this.handleError))).toPromise();
  }

  getEventDetailsFromID(eventID) {
    const param = new HttpParams().set('eventid', eventID);
    return this.http.get(this.baseAPIurl + `api/eventdetails`,
      { observe: 'response', params: param }).pipe(map(data => { return data.body },
      catchError(this.handleError))).toPromise();
  }

  submitCancelled(action, responderid, EventId, Cancelled, IsGuest,interestedInFutureEvent) {
    var params = {
      "action": action,
      "prospectId": responderid,
      "eventId": EventId,
      "cancelled": Cancelled,
      "IsGuest": IsGuest,
      "MoveToFutureEvent": interestedInFutureEvent ? 1 : 0
    }
    return this.http.post(this.baseAPIurl2 + 'api/event/edit', params, this.httpOptions).pipe(map(data => {
    }, catchError(this.handleError)
    ));
  }

  submitCheckIn(action, responderid, EventId, CheckIn, IsGuest) {
    var params = {
      "action": action,
      "prospectId": responderid,
      "eventId": EventId,
      "checkin": CheckIn,
      "IsGuest": IsGuest
    }
    return this.http.post(this.baseAPIurl2 + 'api/event/edit', params, this.httpOptions).pipe(map(data => {
    }, catchError(this.handleError)
    ));
  }

  submitBulkAction(action, prospectID, eventID, isGuest,interestedInFutureEvent) {
    var params = {
      "action": action,
      "prospectList": prospectID,
      "eventId": eventID,
      "IsGuest": isGuest,
    }
    if (action === "checkinall") params["checkin"] = 1;
    else if (action === "cancelall"){
      params["cancelled"] = 1;
      params["MoveToFutureEvent"] = interestedInFutureEvent ? 1 : 0;
    }
    return this.http.post(this.baseAPIurl2 + 'api/event/edit', params, this.httpOptions).pipe(map(data => {
    }, catchError(this.handleError)
    ));
  }


  upsertReminderCall(action: string, ReminderCallId: number, ResponderId: number, EventId: number, ReminderCallDispositionId: number, CallDate: string, isDeleted: boolean): Observable<any> {
    var params = {
      "action": action,
      "ReminderCallId": ReminderCallId,
      "ResponderId": ResponderId,
      "EventId": EventId,
      "ReminderCallDispositionId": ReminderCallDispositionId,
      "CallDate": CallDate,
      "isDeleted": isDeleted
    }
    return this.http.post(this.baseAPIurl2 + 'api/event/edit', params, this.httpOptions).pipe(map(data => {
    }, catchError(this.handleError)
    ));
  }

  saveProspectMenuItem(action, Type, ResponderId, GuestId, EventId, MenuItemId) {
    var params = {
      "action": action,
      "Type": Type,
      "ResponderId": ResponderId,
      "GuestId": GuestId,
      "EventId": EventId,
      "MenuItemId": MenuItemId
    }
    return this.http.post(this.baseAPIurl2 + 'api/event/edit', params, this.httpOptions).pipe(map(data => {
    }, catchError(this.handleError)
    ));
  }

  //Error handling
  handleError(error: { error: { message: string; }; status: any; message: any; }) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    }
    else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }
}

