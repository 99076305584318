import { UserManagerSettings } from "oidc-client";

export const environmentProd = {
  production: true,
  APP_BASE_URL:"./../../../assets/data/",
  dashboardProspectApiURL: 'https://prod-prospectapi.lrsphub.com/',
  contactApiURL:'https://prod-contactapi.lrsphub.com/',
  campaignApiURL: 'https://prod-campaignapi.lrsphub.com/',
  messageHistoryApiURL:'https://prod-messagehistoryapi.lrsphub.com/',  
  eventApiURL: 'https://prod-eventapi.lrsphub.com/',
  responsesettingEventEditApiURL:'https://prod-prospecteventeditapi.lrsphub.com/',  
  integrationExportsApiURL:'https://prod-exportsapi.lrsphub.com/',
  OneLoginApiURL:'https://Oneloginsso-env-prod.lrsphub.com/',
  achSuiteletURL:'https://1014080.extforms.netsuite.com/app/site/hosting/scriptlet.nl?script=customscript_cardxpayment_sl&deploy=customdeploy_cardxpayment_sl&compid=1014080&h=72a31c17466d2d77aaae&paytype=achresponse',
  achFormURL: 'https://1014080.extforms.netsuite.com/app/site/hosting/scriptlet.nl?script=customscript_cardxpayment_sl&deploy=customdeploy_cardxpayment_sl&compid=1014080&h=72a31c17466d2d77aaae&paytype=customachform',
  hubCommunicationApis: 'https://prod-sending-mail-aws-ses-api.lrsphub.com/',
  uspsPostalStatement: 'https://uspscsvdata-prod.lrsphub.com/',
  authUserName:"admin",
  authPassword:"Rk2iK4tZm5I3",
  OneloginTimeout:'1800000',
};

export const clientSettingsProd: UserManagerSettings = {
  authority: 'https://leadingresponse.onelogin.com/oidc/2',
  client_id: '303a12f0-ef0f-013a-a431-067ff875870f192627',
  redirect_uri: 'https://lrsphub.com/auth-callback',
  post_logout_redirect_uri: 'https://leadingresponse.onelogin.com/login2',
  response_type:"id_token token",
  scope:"openid profile",
  filterProtocolClaims: true,
  loadUserInfo: true
}

export const PaymentURLProd:string="https://1014080.extforms.netsuite.com/app/site/hosting/scriptlet.nl?script=customscript_lrpayprocess&deploy=customdeploy_lrpayprocess&compid=1014080&h=cf061391e7fd4c41db52&tranno=<Salesorderno#Invoice#>&trantype=4";