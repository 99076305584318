import { UserManagerSettings } from "oidc-client";

export const environmentQa = {
  production: false,
  APP_BASE_URL: "./../../../assets/data/",
  dashboardProspectApiURL: 'https://qa-prospectapi.lrsphub.com/',
  contactApiURL: 'https://qa-contactapi.lrsphub.com/',
  campaignApiURL: 'https://qa-campaignapi.lrsphub.com/',
  messageHistoryApiURL: 'https://qa-messagehistoryapi.lrsphub.com/',
  eventApiURL: 'https://qa-eventapi.lrsphub.com/',
  responsesettingEventEditApiURL: 'https://qa-responsesettingsapi.lrsphub.com/',
  integrationExportsApiURL: 'https://qa-integrationandexportsapi.lrsphub.com/',
  OneLoginApiURL: 'https://qa-Oneloginssoenv.lrsphub.com/',
  achSuiteletURL: 'https://1014080-sb1.extforms.netsuite.com/app/site/hosting/scriptlet.nl?script=customscript_cardxpayment_sl&deploy=customdeploy_cardxpayment_sl&compid=1014080_SB1&h=fe2c8eb74472b00ba71d&paytype=achresponse',
  achFormURL: 'https://1014080-sb1.extforms.netsuite.com/app/site/hosting/scriptlet.nl?script=customscript_cardxpayment_sl&deploy=customdeploy_cardxpayment_sl&compid=1014080_SB1&h=fe2c8eb74472b00ba71d&paytype=customachform',
  hubCommunicationApis: 'https://qa-sendingmail-awsses-api.lrsphub.com/',
  uspsPostalStatement: 'https://uspscsvdata-nonprod.lrsphub.com/',
  authUserName: "admin",
  authPassword: "Rk2iK4tZm5I3",
  OneloginTimeout: '1800000',
};

export const clientSettingsQA: UserManagerSettings = {
  authority: 'https://leadingresponse.onelogin.com/oidc/2',
  client_id: '750e9000-bda7-013a-7b8c-029937692b75192627',
  redirect_uri: 'https://qa.lrsphub.com/auth-callback',
  post_logout_redirect_uri: 'https://leadingresponse.onelogin.com/login2',
  response_type: "id_token token",
  scope: "openid profile",
  filterProtocolClaims: true,
  loadUserInfo: true
}

export const PaymentURLQA: string = "https://1014080-sb1.extforms.netsuite.com/app/site/hosting/scriptlet.nl?script=customscript_lrpayprocess&deploy=customdeploy_lrpayprocess&compid=1014080_SB1&h=ed12ad571b8e6e4ab424&tranno=<Salesorderno#Invoice#>&trantype=4";