<div [ngClass]="brandClass">

    <app-page-loader [showLoader]="showLoader"></app-page-loader>

    <section class="attendance">

        <div class="attendance__event event">
            <p-toast position="top-center"></p-toast>
            <div class="event__header" [ngClass]="event.typeClass">
                <div class="event__header-top">
                    <!-- <div class="event__date-box">
                        <div class="event__db-month" [ngClass]="event.typeClass">{{ event.eventDate | date:'MMM' | uppercase }}</div>
                        <div class="event__db-date" [ngClass]="event.typeClass">{{ event.eventDate | date:'d' }}</div>
                    </div> -->
                    <div class="event__venue-wrapper">
                        <div class="event__venue-header" [ngClass]="event.typeClass">
                            <img class="event__type-icon" [src]="event.iconWhite">
                            <div>
                                {{ event.eventType }}
                                <div class="event__date">{{ event.eventDate | date:'fullDate' }}<!-- - {{ event.time.charAt(0) === "0" ? event.time.substring(1) : event.time }}--></div>
                            </div>
                        </div>
                        <div class="event__main" [ngClass]="event.typeClass">
                            <div class="event__venue" [ngClass]="event.typeClass">
                                <div class="event__venue-name">{{ event.venueName }}</div>
                                <div class="event__location">
                                    <span class="event__address">{{ event.address1 }}</span>&nbsp;
                                    <span class="event__city-state">{{ event.city }}, {{ event.state }}</span>
                                </div>  
                            </div>    
                            <div class="event__menu-wrapper">
                                <!-- <div class="event__menu-header" [ngClass]="event.typeClass">
                                    <img class="event__menu-icon" src="../assets/images/EventMenu_White.svg">
                                    Menu
                                </div> -->
                                <div class="event__menu" [ngClass]="event.typeClass">
                                    <div class="event__menu-item" *ngFor="let menuitem of event.menu">
                                        <div class="event__menu-label">{{menuitem.menuItem}}:</div>
                                        <div class="event__menu-count">{{menuitem.totalResponderCount}}</div>
                                    </div>
                                    <div class="event__menu-item" *ngIf="event.menu.length === 0">
                                        <div class="event__menu-label">No menu selections for this event</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>


        <div class="attendance__operations">
            <div class="attendance__informational informational informational--basic">
                <div class="informational__icon-wrapper">
                    <img class="informational__icon" src="./assets/images/Alert_Primary.svg" title="Note">
                </div>
                <div class="informational__content">
                    <span>To check in or cancel multiple prospects and/or guests at once, select the checkboxes next to the prospects and/or guests and click the corresponding button.</span>
                </div>
            </div>
            <div class="attendance__buttons">
                <button class="button button--check-in button--icon" (click)="clickBulkAction('checkin')" [disabled]="!bulkEnabled || !canCheckIn" [title]="tooltipCheckIn">
                    <img class="button__icon" [src]="eventStatuses['checked-in'].iconWhite">
                    <span>Check In Selected</span>
                </button>
                <button class="button button--cancel button--icon" (click)="clickBulkAction('cancel')" [disabled]="!bulkEnabled" [title]="tooltipCancel">
                    <img class="button__icon" [src]="eventStatuses['cancelled'].iconWhite">
                    <span>Cancel Selected</span>
                </button>
            </div>    
        </div>

        <div class="attendance__prospects">

            <div class="attendance__list-header">
                <div class="attendance__th attendance__th--name">
                    Prospect
                </div>
                <div class="attendance__th attendance__th--contact">
                    Contact
                </div>
                <div class="attendance__th attendance__th--confirmation">
                    Confirmation Status
                </div>
                <div class="attendance__th attendance__th--meal">
                    Meal Selection
                </div>
                <div class="attendance__th attendance__th--actions">
                    Actions
                </div>
                <div class="attendance__th attendance__th--checkbox">
                </div>
            </div>

            <ul class="attendance__list" *ngIf="prospects !== null">
                <li class="attendance__prospect" *ngFor="let prospect of prospects">
                    <div class="attendance__cell attendance__name">
                        {{ prospect.responderFirstName }} {{ prospect.responderLastName }}
                        <div class="attendance__name-contact">
                            <div class="prospect-icon" *ngIf="prospect.doNotcontact">
                                <img [src]="'./assets/images/DonotContact.svg'">
                            </div>
                            <span *ngIf="!prospect.doNotcontact">{{ prospect.phoneNumber !== null ? prospect.phoneNumber : prospect.alternateNumber }}</span>
                            <span *ngIf="!prospect.doNotcontact">{{ prospect.email }}</span>    
                        </div>
                    </div>
                    <div class="attendance__cell attendance__contact">
                        <div class="prospect-icon" *ngIf="prospect.doNotcontact">
                            <img [src]="'./assets/images/DonotContact.svg'">
                        </div>
                        <span *ngIf="!prospect.doNotcontact">{{ prospect.phoneNumber !== null ? prospect.phoneNumber : prospect.alternateNumber }}</span>
                        <span *ngIf="!prospect.doNotcontact">{{ prospect.email }}</span>
                    </div>
                    <div class="attendance__cell attendance__confirmation">
                        <label class="attendance__field-label">Confirmation Status: </label>
                        <select class="input-control input-control--basic" #confirmationStatus
                            [(ngModel)]="prospect.reminderCallDispositionId"
                            (change)='updateConfirmationStatus(prospect.responderid, prospect.eventId, confirmationStatus.value)'>
                            <option [value]="0">Please select</option>
                            <option *ngFor="let status of confirmationStatuses" [value]="status.reminderCallDispositionId">
                                {{status.name}}
                            </option>
                        </select>
                    </div>
                    <div class="attendance__cell attendance__meal">
                        <label class="attendance__field-label">Meal Selection: </label>
                        <select class="input-control input-control--basic" *ngIf="event.menu.length > 0" [(ngModel)]="prospect.eventmenuItemId" #prospectMenuItem
                            (change)='updateMenuSelection("prospect", prospect.responderid, 0, prospect.eventId, prospectMenuItem.value)'>
                            <option *ngIf="event.menu.length > 0" [value]="0">Please select</option>
                            <option *ngFor="let menuItem of event.menu"  [value]="menuItem.eventMenuItemId">
                                {{menuItem.menuItem}}
                            </option>
                        </select>
                        <select class="input-control input-control--basic" disabled *ngIf="event.menu.length === 0">
                            <option value="0" selected></option>
                        </select>
                    </div>
                    <div class="attendance__cell attendance__actions">
                        <div class="attendance__action">
                            <div class="prospect-icon notes enabled"
                                (click)="clickNotes(prospect.responderid, prospect.responderFirstName, prospect.responderLastName)">
                                    <img [src]="prospect.hasNotes ? './assets/images/NotesSelected.svg' : './assets/images/Notes.svg'"
                                        data-toggle="tooltip" title="Notes">
                            </div>
                        </div>
                        <div class="attendance__action">
                            <div class="prospect-icon checked-in enabled active"
                                *ngIf="prospect.status === 'CHECKED IN'"
                                (click)="clickCheckIn(prospect.responderid, 0)"
                                title="Already Checked In">
                                <img [src]="eventStatuses['checked-in'].iconWhite" alt="Checked In" title="Checked In">
                            </div>
                            <div class="prospect-icon checked-in enabled"
                                *ngIf="prospect.status !== 'CHECKED IN' && !prospect.cancelled && prospect.eventValidity <= checkInHours"
                                (click)="clickCheckIn(prospect.responderid, 1)"
                                title="Check In">
                                    <img [src]="eventStatuses['checked-in'].iconColor" alt="">
                            </div>
                            <div class="prospect-icon checked-in"
                                *ngIf="prospect.status !== 'CHECKED IN' && (prospect.cancelled || prospect.eventValidity > checkInHours)">
                                    <img [src]="eventStatuses['checked-in'].iconDisabled" *ngIf="prospect.cancelled && prospect.eventValidity <= checkInHours"
                                        title="Remove Cancelled Status to Enable" alt="">
                                    <img [src]="eventStatuses['checked-in'].iconDisabled" alt=""
                                        *ngIf="prospect.eventValidity > checkInHours"
                                        title="Enabled 8 Hour Prior to Event Start">
                            </div>
                        </div>
                        <div class="attendance__action">
                            <div class="prospect-icon cancelled enabled active"
                                *ngIf="prospect.cancelled" title="Already Cancelled"
                                (click)="clickCancel(prospect.responderid, 0)">
                                    <img [src]="eventStatuses['cancelled'].iconWhite" alt="">
                            </div>
                            <div class="prospect-icon cancelled enabled" *ngIf="!prospect.cancelled" 
                            (click)="openCancelConfirmPopup('Cancel',prospect.responderid,prospect.interestedInFutureEvent)">
                                    <img [src]="eventStatuses['cancelled'].iconColor" alt="" title="Cancel">
                            </div>
                        </div>
                    </div>
                    <div class="attendance__cell attendance__checkbox">
                        <input type="checkbox" [checked]="prospect.checked" (click)="clickCheckbox(prospect, null)" />
                    </div>
                    <div class="attendance__cell attendance__guests" *ngIf="prospect.guestDetails.length > 0">
                        <div class="guest-card__header">Guests:</div>
                        <div class="guest-card__list">
                            <div class="attendance__guest guest-card" *ngFor="let guest of prospect.guestDetails">
                                <div class="guest-card__cell guest-card__name">
                                    {{ guest.guestFirstName }} {{ guest.guestLastName }} 
                                    <div class="guest-card__name-relationship">{{ getRelationshipType(guest.relationshipId) }}</div>
                                </div>
                                <div class="guest-card__cell guest-card__relationship">
                                    {{ getRelationshipType(guest.relationshipId) }}
                                </div>
                                <div class="guest-card__meal">
                                    <label class="attendance__field-label">Meal Selection: </label>
                                    <select class="input-control input-control--basic" [(ngModel)]="guest.eventmenuItemId" *ngIf="event.menu.length > 0" #guestMenuItem
                                        (change)='updateMenuSelection("guest", prospect.responderid, guest.guestId, prospect.eventId, guestMenuItem.value)'>
                                            <option *ngIf="event.menu.length > 0" [value]="0">Please select</option>
                                            <option *ngFor="let menuItem of event.menu"
                                                [value]="menuItem.eventMenuItemId">
                                                {{menuItem.menuItem}}
                                            </option>
                                    </select>
                                    <select class="input-control input-control--basic" disabled *ngIf="event.menu.length === 0">
                                        <option value="0" selected></option>
                                    </select>
                                </div>
                                <div class="guest-card__cell guest-card__actions">
                                    <div class="guest-card__action">
                                        <div class="prospect-icon checked-in enabled active cls-icon-true"
                                            *ngIf="guest.isGuestCheckedIn"
                                            (click)="clickCheckInGuest(guest.guestId, 0)">
                                                <img [src]="eventStatuses['checked-in'].iconWhite" alt=""
                                                    title="Already Checked In">
                                        </div>
                                        <div class="prospect-icon checked-in enabled"
                                            *ngIf="!guest.isGuestCheckedIn && !guest.isGuestCancelled && prospect.eventValidity <= checkInHours"
                                            (click)="clickCheckInGuest(guest.guestId, 1)">
                                                <img [src]="eventStatuses['checked-in'].iconColor" data-toggle="tooltip"
                                                    title="Check In" alt="">
                                        </div>
                                        <div class="prospect-icon checked-in"
                                            *ngIf="!guest.isGuestCheckedIn && (guest.isGuestCancelled || prospect.eventValidity > checkInHours)">
                                                <img [src]="eventStatuses['checked-in'].iconDisabled" alt=""
                                                    *ngIf="guest.isGuestCancelled && prospect.eventValidity <= checkInHours"
                                                    title="Remove Canceled Status to Enable">
                                                <img [src]="eventStatuses['checked-in'].iconDisabled" alt=""
                                                    *ngIf="prospect.eventValidity > checkInHours"
                                                    title="Enabled 8 Hour Prior to Event Start">
                                        </div>
                                    </div>
                                    <div class="guest-card__action">
                                        <div class="prospect-icon cancelled active enabled"
                                            *ngIf="guest.isGuestCancelled"
                                            (click)="clickCancelGuest(guest.guestId, 0)">
                                            <img [src]="eventStatuses['cancelled'].iconWhite" alt=""
                                                title="Already Cancelled">
                                        </div>
                                        <div class="prospect-icon cancelled enabled"
                                            *ngIf="!guest.isGuestCancelled" 
                                            (click)="clickCancelGuest(guest.guestId, 1)">
                                            <img [src]="eventStatuses['cancelled'].iconColor" alt="" title="Cancel">
                                        </div>
                                    </div>
                                </div>
                                <div class="guest-card__cell guest-card__checkbox">
                                    <input type="checkbox" [checked]="guest.checked" (click)="clickCheckbox(null, guest)" />
                                </div>        
                            </div>    
                        </div>
                    </div>
                </li>
            </ul>

        </div>

    </section>

    <div class="confirmation-dialog">
        <p-dialog header="Confirmation" [(visible)]="showConfirmationDialog" [modal]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
            <div class="confirmation-dialog__main">
                <p>{{ confirmationMessage }}</p>
                <div class="confirmation-dialog__buttons">
                    <button class="button button--ghost" type="button" (click)="clickConfirm(false)">
                            No
                    </button>
                    <button class="button button--primary" type="button" (click)="clickConfirm(true)">
                            Yes
                    </button>
                </div>
            </div>
        </p-dialog>
    </div>

    <app-notes-manager-dialog *ngIf="showNotesDialog" [showDialog]="showNotesDialog" [prospectID]="selectedProspectID" [prospectName]="selectedProspectName" (closePopup)="notesClosed($event)"></app-notes-manager-dialog>

</div>