import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor{

  token: string;
  omitCalls = ['login'];
  skipInterceptor = false;
  constructor(private router: Router) { }//, private facadeService: FacadeService

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.omitCalls.forEach(api => {
      if (req.url.includes(api)) {
        this.skipInterceptor = true;
      }
    });
    //this.token = this.facadeService.getUserToken();
    this.token="temp";
    if (this.token || this.skipInterceptor) {
      //const tokenizedReq = req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + this.token) });
      const tokenizedReq = req.clone({ headers: req.headers.set('Authorization', 'Basic ' + btoa(environment.authUserName + ':' + environment.authPassword)) });
      return next.handle(tokenizedReq).pipe(map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          if (event.status === 401) {
            //this.facadeService.userLoggedOut();
            this.router.navigateByUrl('/login');
          }
        }
        return event;
      }));
    } else {
      //this.facadeService.userLoggedOut();
      this.router.navigateByUrl('/dashboard');
    }
    return next.handle(req);
  }
}
