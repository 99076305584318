import { Component, Input, OnInit } from '@angular/core';
// import { Eventservice as EventService } from '../../prospects/prospects.service';
import { Eventservice as EventService } from './attendance-manager.service';
import { DatePipe } from '@angular/common';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-attendance-manager',
  templateUrl: './attendance-manager.component.html',
  styleUrls: ['./attendance-manager.component.scss'],
  providers: [DatePipe, MessageService]
})
export class AttendanceManagerComponent implements OnInit {
  @Input() eventID: string = "";
  
  event: any = {
    eventType: '',
    eventDate: '',
    venueName: '',
    address1: '',
    city: '',
    state: '',
    typeClass: 'other',
    menu: [],
    "iconColor": "./assets/images/EventTypeOther_Color.svg",
    "iconWhite": "./assets/images/EventTypeOther_White.svg"
  };
  prospects: any = null;

  showLoader: boolean = false;
  showNotesDialog: boolean = false;
  showConfirmationDialog: boolean = false;

  bulkEnabled: boolean = false;
  bulkAction: string = "";
  showNotes: boolean = true;
  selectedProspectID: string = '';
  selectedProspectName: string = '';

  eventInitialized: boolean = false;
  prospectsInitialized: boolean = false;

  confirmationMessage: string = '';

  relationshipTypes: any = null;
  confirmationStatuses: any = null;

  checkInHours: number = 0;
  canCheckIn: boolean = true;

  tooltipCheckIn: string = "Select Prospects/Guests to Enable";
  tooltipCancel: string = "Select Prospects/Guests to Enable";

  //PCNG-2146 
  action:string;
  prospectResponderId:number;
  bulkCancelClicked:boolean = false;

  // Event Type Styling
  eventTypes:any = {
    "Breakfast": {
      "class": "breakfast",
      "iconColor": "./assets/images/EventTypeBreakfast_Color.svg",
      "iconWhite": "./assets/images/EventTypeBreakfast_White.svg"
    },
    "Continental Breakfast": {
      "class": "continental-breakfast",
      "iconColor":"./assets/images/EventTypeContinentalBreakfast_Color.svg",
      "iconWhite": "./assets/images/EventTypeContinentalBreakfast_White.svg"
    },
    "Lunch": {
      "class": "lunch",
      "iconColor": "./assets/images/EventTypeLunch_Color.svg",
      "iconWhite": "./assets/images/EventTypeLunch_White.svg"
    },
    "Dinner": {
      "class": "dinner",
      "iconColor": "./assets/images/EventTypeDinner_Color.svg",
      "iconWhite": "./assets/images/EventTypeDinner_White.svg"
    },
    "Hors d'Oeuvres": {
      "class": "hors-doeuvres",
      "iconColor": "./assets/images/EventTypeHorsDoeuvres_Color.svg",
      "iconWhite": "./assets/images/EventTypeHorsDoeuvres_White.svg"
    },
    "Refreshments": {
      "class": "refreshments",
      "iconColor": "./assets/images/EventTypeRefreshments_Color.svg",
      "iconWhite": "./assets/images/EventTypeRefreshments_White.svg"
    },
    "Live Webinar": {
      "class": "live-webinar",
      "iconColor": "./assets/images/EventTypeLiveWebinar_Color.svg",
      "iconWhite": "./assets/images/EventTypeLiveWebinar_White.svg"
    },
    "Other": {
      "class": "other",
      "iconColor": "./assets/images/EventTypeOther_Color.svg",
      "iconWhite": "./assets/images/EventTypeOther_White.svg"
    }
  }

  // Event Status Styling
  eventStatuses:any = {
    "cancelled": {
      "class": "cancelled",
      "iconColor": "./assets/images/StatusCancel_Color.svg",
      "iconDisabled": "./assets/images/StatusCancel_Gray.svg",
      "iconWhite": "./assets/images/StatusCancel_White.svg"
    },
    "checked-in": {
      "class": "checked-in",
      "iconColor": "./assets/images/StatusCheckIn_Color.svg",
      "iconDisabled": "./assets/images/StatusCheckIn_Gray.svg",
      "iconWhite": "./assets/images/StatusCheckIn_White.svg"
    },
    "disqualify": {
      "class": "disqualify",
      "iconColor": "./assets/images/StatusDisqualify_Color.svg",
      "iconDisabled": "./assets/images/StatusDisqualify_Gray.svg",
      "iconWhite": "./assets/images/StatusDisqualify_White.svg"
    },
    "future-event": {
      "class": "future-event",
      "iconColor": "./assets/images/StatusFutureEvent_Color.svg",
      "iconDisabled": "./assets/images/StatusFutureEvent_Gray.svg",
      "iconWhite": "./assets/images/StatusFutureEvent_White.svg"
    },
    "reserved": {
      "class": "reserved",
      "iconColor": "./assets/images/StatusReserved_Color.svg",
      "iconDisabled": "./assets/images/StatusReserved_Gray.svg",
      "iconWhite": "./assets/images/StatusReserved_White.svg"
    },
    "wait-list": {
      "class": "wait-list",
      "iconColor": "./assets/images/StatusWaitlist_Color.svg",
      "iconDisabled": "./assets/images/StatusWaitlist_Gray.svg",
      "iconWhite": "./assets/images/StatusWaitlist_White.svg"
    }
  }

  // Information Pop-Up Functionality
  // PCNG-2476
  @Input() showHelp: boolean = false;  //Determines whether or not to include a help/info component.

  // Branding for whitelabeling
  public brand: string = 'hub'; // Brand for whitelabeling
  public brandClass: string = 'brand--hub'; // Brand class for whitelabeling
  
  constructor(private datePipe: DatePipe, private eventService: EventService, private messageService: MessageService) { }

  ngOnInit(): void {
    this.showLoader = true;
    this.getGlobalKeyValues();
    this.getRelationshipTypes();
    this.getConfirmationStatuses();
    this.getEventDetails(this.eventID);
    this.getProspects(this.eventID);
    // Branding for whitelabeling
    if (sessionStorage.getItem('brand') !== '') {
      this.brand = sessionStorage.getItem('brand');
      this.brandClass = 'brand--' + this.brand;
    };            
  }

  async getEventDetails(eventID) {
    let responseData = await this.eventService.getEventDetailsFromID(eventID);
    this.event = responseData[0];
    this.setEventStyles();
    this.eventInitialized = true;
    if (this.prospectsInitialized) this.showLoader = false;
  }

  async getProspects(eventID) {
    // Update this function with a new endpoint, when available.
    this.prospects = await this.eventService.getMarkerDetailsLP(eventID);
    this.prospects = this.prospects.filter(prospect => prospect.isAssured !== 1);
    this.prospects.forEach(prospect => {
      prospect.checked = false
      if (prospect.responderNoteId !== 0) prospect.hasNotes = true;
      else prospect.hasNotes = false;
      prospect.guestDetails.forEach(guest => guest.checked = false);
    });
    if (this.prospects.length > 0) {
      let eventValidity = this.prospects[0].eventValidity;
      if (eventValidity > this.checkInHours) {
        this.canCheckIn = false;
        this.tooltipCheckIn = "Cannot Check In Until " + this.checkInHours + " Hours Before Event";
      }
    }
    this.prospectsInitialized = true;
    if (this.eventInitialized) this.showLoader = false;
  }

  setEventStyles() {
    if (this.event.eventType) {
      this.event.typeClass = this.eventTypes[this.event.eventType].class;
      this.event.iconColor = this.eventTypes[this.event.eventType].iconColor;
      this.event.iconWhite = this.eventTypes[this.event.eventType].iconWhite;
    }
    else {
      this.event.typeClass = this.eventTypes["Other"].class;
      this.event.iconColor = this.eventTypes["Other"].iconColor;
      this.event.iconWhite = this.eventTypes["Other"].iconWhite;
    }

  }

  clickCheckIn(prospectID, doCheckIn) {
    this.showLoader = true;
    this.eventService.submitCheckIn("checkin", prospectID, this.eventID, doCheckIn, 0).subscribe(() => {
        this.getProspects(this.eventID);
      },
      error => { 
        this.showLoader = false; 
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'An error occurred' });
      }
    );
  }

  clickCancel(prospectID, doCancel, interestedInFutureEvent:boolean=false) {
    this.showLoader = true;
    this.eventService.submitCancelled("cancel", prospectID, this.eventID, doCancel, 0, interestedInFutureEvent).subscribe(() => {
        this.getProspects(this.eventID);
      },
      error => { 
        this.showLoader = false; 
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'An error occurred' });
      }
    );
  }

     /** display popup only when prospects is not interested in future event previously  */ 
  openCancelConfirmPopup(action,id:number,interestedInFutureEvent:boolean= false) {
    this.action = action;
    this.bulkCancelClicked = false;
    this.prospectResponderId = id;
    this.bulkAction = '';
    if(!interestedInFutureEvent){
      this.confirmationMessage = "Add this Prospect to ‘Interested in Future Event’?";
      this.showConfirmationDialog = true;
    }else{
      this.clickConfirm(true);
    }
  
  }

  clickCheckInGuest(guestID, doCheckIn) {
    this.showLoader = true;
    this.eventService.submitCheckIn("checkin", guestID, this.eventID, doCheckIn, 1).subscribe(() => {
      this.getProspects(this.eventID);
    },
    error => { 
      this.showLoader = false; 
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'An error occurred' });
    }
  );
}

  clickCancelGuest(guestID, doCancel) {
    this.showLoader = true;
    this.eventService.submitCancelled("cancel", guestID, this.eventID, doCancel, 1, false).subscribe(() => {
      this.getProspects(this.eventID);
    },
    error => { 
      this.showLoader = false; 
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'An error occurred' });
    }
    );
  }

  clickCheckbox(prospect, guest) {
    if (prospect !== null) prospect.checked = !prospect.checked;
    else if (guest !== null) guest.checked = !guest.checked;
    let prospectsChecked = this.prospects.some(prospect => prospect.checked);
    let guestsChecked = this.prospects.some(prospect => prospect.guestDetails.some(guest => guest.checked));
    if (prospectsChecked || guestsChecked) {
      this.bulkEnabled = true;
      this.tooltipCheckIn = "Check In All Selected Prospects/Guests";
      this.tooltipCheckIn = "Cancel In All Selected Prospects/Guests";
    }
    else {
      this.bulkEnabled = false;
      if (this.canCheckIn) this.tooltipCheckIn = "Select Prospects/Guests to Enable";
      else this.tooltipCheckIn = "Cannot Check In Until " + this.checkInHours + " Hours Before Event";
      this.tooltipCancel = "Select Prospects/Guests to Enable";
    }
  }

  clickBulkAction(action) {
    this.bulkAction = action;
    this.bulkCancelClicked= false;
    this.action = '';
    this.confirmationMessage = "Do you want to " + action.toLowerCase().replace("checkin", "check in") + " all selected prospects and guests?";
    this.showConfirmationDialog = true;
  }

  clickConfirm(confirmed) {
    this.showConfirmationDialog = false;
    if(this.action=== 'Cancel'){
      this.clickCancel(this.prospectResponderId, 1 ,confirmed);
    }else if(this.action === 'bulkCancel' && this.bulkCancelClicked){
      this.completeBulkCancel(confirmed);
    }
    if (confirmed) {
      if (this.bulkAction === "checkin") this.completeBulkCheckIn();
      else if (this.bulkAction === "cancel") {
        this.bulkAction = '';
        this.action = 'bulkCancel';
        this.bulkCancelClicked = true;
        let selectedProspects =  this.prospects.filter(item => item.checked).map(item =>item.responderid);
        let selectedProspectsWithFutureEvent = this.prospects.filter(item => item.checked && item.isFutureEvent).map(item =>item.responderid);
        if(selectedProspects?.length === selectedProspectsWithFutureEvent?.length){
          this.completeBulkCancel(true);
        } else {
          this.confirmationMessage = "Add Selected Prospects to ‘Interested in Future Event’?";
          this.showConfirmationDialog = true;
        }
      }
    }
  }
  
  completeBulkCheckIn() {
    this.showLoader = true;
    let prospectArray = this.prospects.filter(item => item.checked).map(item => item.responderid);
    let prospectString = prospectArray.join(",");
    this.eventService.submitBulkAction("checkinall", prospectString, this.eventID, 0,false).subscribe(
      () => {
        let guestArray = [];
        this.prospects.forEach(prospect => {
          prospect.guestDetails.forEach(guest => {
            if (guest.checked) guestArray.push(guest.guestId);
          })
        })
        if (guestArray.length > 0) {
          let guestString = guestArray.join(",");
          this.eventService.submitBulkAction("checkinall", guestString, this.eventID, 1,false).subscribe(
            () => {
              this.getProspects(this.eventID);
            },
            error => {
              this.getProspects(this.eventID);
            }
          );
        }
        else this.getProspects(this.eventID);  
      },
      error => { 
        this.showLoader = false; 
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'An error occurred' });
      }
    );
  }

  completeBulkCancel(interestedInFutureEvent:boolean=false) {
    this.showLoader = true;
    let prospectArray = this.prospects.filter(item => item.checked).map(item => item.responderid);
    let prospectString = prospectArray.join(",");
    this.eventService.submitBulkAction("cancelall", prospectString, this.eventID, 0,interestedInFutureEvent).subscribe(
      () => {
        let guestArray = [];
        this.prospects.forEach(prospect => {
          prospect.guestDetails.forEach(guest => {
            if (guest.checked) guestArray.push(guest.guestId);
          })
        })
        if (guestArray.length > 0) {
          let guestString = guestArray.join(",");
          this.eventService.submitBulkAction("cancelall", guestString, this.eventID, 1, false).subscribe(
            () => {
              this.getProspects(this.eventID);
            },
            error => {
              this.getProspects(this.eventID);
            }
          );
        }
        else this.getProspects(this.eventID);  
      },
      error => { 
        this.showLoader = false; 
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'An error occurred' });
      }
    );
  }

  clickNotes(prospectID, prospectFirstName, prospectLastName) {
    this.selectedProspectID = prospectID;
    this.selectedProspectName = prospectFirstName + " " + prospectLastName;
    this.showNotesDialog = true;
  }

  notesClosed() {
    this.showLoader = true;
    this.showNotesDialog = false;
    this.getProspects(this.eventID);
  }

  updateConfirmationStatus(prospectID, eventID, confirmationStatus) {
    this.showLoader = true;
    this.eventService.upsertReminderCall("upsertremindercall", 0, prospectID, eventID, confirmationStatus, this.datePipe.transform(new Date(), "yyyy-MM-dd HH:mm:ss"), false)
    .subscribe(data => {
      this.getProspects(this.eventID);
      // this.messageService.add({ severity: 'success', summary: 'LRSP Hub', detail: "Confirmation Status updated Successfully." });
    },
      error => { 
        this.showLoader = false; 
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'An error occurred' });
      }
    );
  }

  updateMenuSelection(prospectType, prospectID, guestID, eventID, menuItemID) {
    this.showLoader = true;
    this.eventService.saveProspectMenuItem("UpdateMenuItem", prospectType, prospectID, guestID, eventID, Number(menuItemID))
    .subscribe(data => {
        this.eventInitialized = false;
        this.prospectsInitialized = false;
        this.getEventDetails(this.eventID);
        this.getProspects(this.eventID);
        // this.messageService.add({ severity: 'success', summary: 'LRSP Hub', detail: "Prospect Meal Selection saved successfully." });
      },
      error => {
        this.showLoader = false;
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'An error occurred' });
      }
    );
  }

  getRelationshipTypes() {
    this.eventService.getrelationtypes().subscribe(
      (data) => {
        this.relationshipTypes = data;
      },
      error => {
      }
    );
  }

  getRelationshipType(id: number) {
    if (this.relationshipTypes === null || this.relationshipTypes.length === 0) return "";
    for (let i = 0; i < this.relationshipTypes.length; i++) {
      if (this.relationshipTypes[i].id === id) return this.relationshipTypes[i].name;
    }
    return "";
  }

  getConfirmationStatuses() {
    this.eventService.getconfirmationStatus().subscribe(
      data => {
        this.confirmationStatuses = data
      },
      error => {
      }
    );
  }

  getGlobalKeyValues() {
    this.eventService.getConfigKeyValuePairs().subscribe(
      (data: any) => {
        data.forEach((element) => {
          if (element.globalKeyName == 'CheckInHour') {
            this.checkInHours = element.globalKeyValue;
            if (this.prospectsInitialized) {
              if (this.prospects.length > 0) {
                let eventValidity = this.prospects[0].eventValidity;
                if (eventValidity > this.checkInHours) {
                  this.canCheckIn = false;
                  this.tooltipCheckIn = "Cannot Check In Until " + this.checkInHours + " Hours Before Event";
                }
              }          
            }
          }
        })
      },
      error => {
      }
    );
  }


}
