<div [ngClass]="brandClass">
    <div class="hub-info">
        <div class="info-icon" [ngClass]="{'small': smallIcon}">
            <img  src="./assets/images/Info_Primary.svg" alt="" data-toggle="tooltip"
                [title]="iconTitle" (click)="openDialog()" />
        </div>
        <div class="information-dialog__wrapper">
            <p-dialog header="Hub Info"
                [(visible)]="infoDialogVisible" [modal]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="false"
                (onHide)="closeInfoDialog()">
                    <section class="information-dialog">
                        <div class="information-dialog__content">
                            <h2 class="information-dialog__title">{{ infoTitle }}</h2>
                            <ng-content></ng-content>
                        </div>
                        <div class="information-dialog__buttons">
                            <button class="button button--ghost button--small" (click)="closeInfoDialog()">Close</button>
                        </div>
                    </section>
            </p-dialog>
        </div> 
    </div>
</div>